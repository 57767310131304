import { map } from './util';

const getFields = (el) => {
  return el.querySelectorAll('input[type="text"], input[type="email"], select, textarea');
};

const loadFields = (fields) => {
  map(fields, (field) => {
    if (sessionStorage.getItem(field.id)) {
      field.value = sessionStorage.getItem(field.id);
    }
  });
};

const saveFields = (fields) => {
  map(fields, (field) => {
    sessionStorage.setItem(field.id, field.value);
  });
};

export const enhancer = el => {
  if (el.id === 'gform_2') {
    el.setAttribute('autocomplete', 'nope');
    const fields = getFields(el);
    loadFields(fields);
    map(fields, (field) => {
      field.setAttribute('autocomplete', 'nope');
      field.addEventListener('change', e => {
        saveFields(fields);
      });
    });

    // clear session
    el.querySelector('#gform_submit_button_2').addEventListener('click', e => {
      sessionStorage.clear();
    });
    document.querySelector('.site-header__logo').addEventListener('click', e => {
      sessionStorage.clear();
    });
    const menuItems = document.querySelectorAll('.nav-primary__item a');
    map(menuItems, (menuItem) => {
      menuItem.addEventListener('click', e => {
        sessionStorage.clear();
      });
    });
  }
};
